import { defineComponent as _defineComponent } from 'vue'
import SVGWorldMap from "@/assets/images/bg/world.svg";

import RedeemRegistrationFormVue from "@ems/containers/SupplyChainManagement/TheForm/RedeemRegistration.Form.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Registration',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { get SVGWorldMap() { return SVGWorldMap }, RedeemRegistrationFormVue }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})