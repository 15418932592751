import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "text-gray-400" }
const _hoisted_2 = { class: "grid grid-cols-2 lg:grid-cols-2 gap-16 xl:gap-28 mt-3" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = { className: "grid grid-cols-12 gap-2 h-12" }
const _hoisted_5 = { class: "col-span-6" }
const _hoisted_6 = { class: "col-span-6" }
const _hoisted_7 = { className: "grid gap-2 h-12 mt-6" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { className: "grid grid-cols-12 gap-2 h-12" }
const _hoisted_10 = { class: "col-span-6" }
const _hoisted_11 = { class: "col-span-6" }
const _hoisted_12 = { class: "grid grid-cols-2 lg:grid-cols-2 gap-16 xl:gap-28 mt-3" }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "" }
const _hoisted_15 = { class: "grid grid-cols-2 lg:grid-cols-2 gap-16 xl:gap-28 mt-3" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = { class: "" }
const _hoisted_18 = { class: "grid grid-cols-2 lg:grid-cols-2 gap-16 xl:gap-28" }
const _hoisted_19 = { class: "" }
const _hoisted_20 = { class: "el-icon el-icon--upload" }
const _hoisted_21 = ["href"]
const _hoisted_22 = { class: "relative" }
const _hoisted_23 = { class: "el-icon el-icon--upload" }
const _hoisted_24 = ["href"]
const _hoisted_25 = { class: "items-center absolute left-44 top-0" }
const _hoisted_26 = {
  type: "button",
  class: "btn bg-none border-gray-700 items-center"
}
const _hoisted_27 = { class: "text-color-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!

  return (_openBlock(), _createBlock($setup["Form"], {
    "validation-schema": $setup.schema,
    as: "el-form",
    onSubmit: $setup.onSubmit,
    "label-position": "top",
    class: "flex justify-center"
  }, {
    default: _withCtx(({ isSubmitting, setFieldValue }) => [
      _createElementVNode("div", {
        class: _normalizeClass(["bg-white py-6 px-5 2xl:py-8 2xl:px-8 shadow relative overflow-hidden box my-8 w-4/5", isSubmitting && 'isSubmitting-rounded'])
      }, [
        _cache[12] || (_cache[12] = _createElementVNode("p", { class: "font-bold xl:text-lg 2xl:text-xl" }, "Invitation code", -1)),
        _createElementVNode("p", _hoisted_1, _toDisplayString($setup.invitationCode), 1),
        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "font-bold xl:text-lg 2xl:text-xl" }, "User Information", -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode($setup["Field"], { name: "Email" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      type: "email",
                      placeholder: "email@gmail.com"
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error", "model-value"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode($setup["Field"], { name: "Password" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          type: "password",
                          placeholder: "Password"
                        }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode($setup["Field"], { name: "ConfirmPassword" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({
                          type: "password",
                          placeholder: "Confirm Password"
                        }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode($setup["Field"], { name: "PhoneNumberUser" }, {
                default: _withCtx(({ value, field, errorMessage }) => [
                  _createVNode(_component_el_form_item, {
                    class: "inputfield",
                    error: errorMessage
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        "model-value": $setup.phoneCodeUser,
                        class: "w-1/5",
                        filterable: "",
                        onChange: (value) => $setup.handlePhoneCodeUser(value)
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PREFIX_PHONE_NUMBER, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.value,
                              label: item.name,
                              value: item.value
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["model-value", "onChange"]),
                      _createVNode(_component_el_input, _mergeProps({
                        class: "w-4/5 pl-3",
                        placeholder: "Phone Number"
                      }, field, { "model-value": value }), null, 16, ["model-value"])
                    ]),
                    _: 2
                  }, 1032, ["error"])
                ]),
                _: 1
              })
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["Field"], { name: "Username" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "Username" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode($setup["Field"], { name: "FirstName" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "Surname (Optional)" }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode($setup["Field"], { name: "LastName" }, {
                  default: _withCtx(({ value, field, errorMessage }) => [
                    _createVNode(_component_el_form_item, {
                      class: "inputfield",
                      error: errorMessage
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, _mergeProps({ placeholder: "Given Name (Optional)" }, field, { "model-value": value }), null, 16, ["model-value"])
                      ]),
                      _: 2
                    }, 1032, ["error"])
                  ]),
                  _: 1
                })
              ])
            ])
          ])
        ]),
        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "font-bold xl:text-lg 2xl:text-xl mt-6" }, "Company Information", -1)),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode($setup["Field"], { name: "CompanyName" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                  error: errorMessage,
                  label: `Company Name*`,
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["class", "error", "model-value"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "PhoneNumber" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: `Company Contact No.*`
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, {
                      "model-value": $setup.phoneCode,
                      class: "w-1/5",
                      filterable: "",
                      onChange: (value) => $setup.handlePhoneCode(value)
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PREFIX_PHONE_NUMBER, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.value,
                            label: item.name,
                            value: item.value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1032, ["model-value", "onChange"]),
                    _createVNode(_component_el_input, _mergeProps({
                      class: "w-4/5 pl-3",
                      placeholder: "Phone Number"
                    }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createVNode($setup["Field"], { name: "CountryId" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield",
                  error: errorMessage,
                  label: "Country of Registration*"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_select, _mergeProps(field, {
                      "model-value": value,
                      class: "w-full",
                      placeholder: "Select Country",
                      filterable: "",
                      onInput: (value) => $setup.remoteMethod(value.target?.value),
                      onChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.remoteMethod('')))
                    }), {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.options, (item) => {
                          return (_openBlock(), _createBlock(_component_el_option, {
                            key: item.Value,
                            label: item.Name,
                            value: item.Value
                          }, null, 8, ["label", "value"]))
                        }), 128))
                      ]),
                      _: 2
                    }, 1040, ["model-value", "onInput"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "CompanyRegistrationNo" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                  error: errorMessage,
                  label: "Company Registration No.",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["class", "error", "model-value"])
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode($setup["Field"], { name: "Address" }, {
          default: _withCtx(({ value, field, errorMessage }) => [
            _createVNode(_component_el_form_item, {
              class: _normalizeClass(['inputfield', _ctx.styles['w-full']]),
              error: errorMessage,
              label: `Address *`
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
              ]),
              _: 2
            }, 1032, ["class", "error"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _createVNode($setup["Field"], { name: "City" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                  error: errorMessage,
                  label: "City*",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["class", "error", "model-value"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "NumberOfWorkers" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: _normalizeClass([$setup.errorMessageRef && 'is-error', 'inputfield']),
                  error: errorMessage,
                  label: "Number of workers in company"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps(field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["class", "error"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_17, [
            _createVNode($setup["Field"], { name: "Province" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: _normalizeClass(['inputfield']),
                  error: errorMessage,
                  label: "Province/State*"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createVNode($setup["Field"], { name: "File" }, {
              default: _withCtx(({ value, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  error: errorMessage,
                  label: "Company Registration Documents / Certificaties*",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_upload, {
                      class: _normalizeClass([
                  $setup.size(value as any) > 2
                    ? 'upload-disable'
                    : _ctx.styles['custom-upload-file'],
                ]),
                      "before-upload": $setup.beforeUploadFile,
                      drag: "",
                      multiple: "",
                      limit: 3,
                      "auto-upload": false,
                      "on-change": (file, showFileList) => $setup.onChangeFile(file, showFileList, value, setFieldValue, 'File'),
                      "on-remove": (file, showFileList) => $setup.handleRemoveUploadedFile(value, file, showFileList, setFieldValue, 'File'),
                      file: "",
                      disabled: $setup.size(value as any) > 2,
                      "on-preview": $setup.handlePreview
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", _hoisted_20, [
                          _createVNode($setup["BaseSvgIcon"], {
                            class: "inline-block group-hover:text-indino-primary w-6 h-6",
                            name: "UploadFile"
                          })
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "el-upload__text" }, "Please upload your files here", -1))
                      ]),
                      _: 2
                    }, 1032, ["class", "on-change", "on-remove", "disabled"]),
                    _cache[8] || (_cache[8] = _createElementVNode("p", null, "Please only select a maximum of 3 files", -1)),
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((value as any), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex w-full justify-between",
                          key: index
                        }, [
                          _createElementVNode("a", {
                            class: "text-blue-500 underline truncate",
                            href: item.Content,
                            target: "_blank"
                          }, _toDisplayString(item.Name), 9, _hoisted_21)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["error", "model-value"])
              ]),
              _: 2
            }, 1024)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode($setup["Field"], { name: "FileLetter" }, {
              default: _withCtx(({ value, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  error: errorMessage,
                  label: "Letter of Authorization*",
                  "model-value": value
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_upload, {
                      class: _normalizeClass([
                  $setup.size(value as any) > 2
                    ? 'upload-disable'
                    : _ctx.styles['custom-upload-file'],
                ]),
                      "before-upload": $setup.beforeUploadFile,
                      drag: "",
                      multiple: "",
                      limit: 3,
                      "auto-upload": false,
                      "on-change": (file, showFileList) => $setup.onChangeFile(file, showFileList, value, setFieldValue, 'FileLetter'),
                      "on-remove": (file, showFileList) => $setup.handleRemoveUploadedFile(value, file, showFileList, setFieldValue, 'FileLetter'),
                      file: "",
                      disabled: $setup.size(value as any) > 2,
                      "on-preview": $setup.handlePreview
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("i", _hoisted_23, [
                          _createVNode($setup["BaseSvgIcon"], {
                            class: "inline-block group-hover:text-indino-primary w-6 h-6",
                            name: "UploadFile"
                          })
                        ]),
                        _cache[9] || (_cache[9] = _createElementVNode("div", { class: "el-upload__text" }, "Please upload your files here", -1))
                      ]),
                      _: 2
                    }, 1032, ["class", "on-change", "on-remove", "disabled"]),
                    _cache[10] || (_cache[10] = _createElementVNode("p", null, "Please only select a maximum of 3 files", -1)),
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((value as any), (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "flex w-full justify-between",
                          key: index
                        }, [
                          _createElementVNode("a", {
                            class: "text-blue-500 underline truncate",
                            href: item.Content,
                            target: "_blank"
                          }, _toDisplayString(item.Name), 9, _hoisted_24)
                        ]))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1032, ["error", "model-value"])
              ]),
              _: 2
            }, 1024),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("button", _hoisted_26, [
                _createVNode($setup["BaseSvgIcon"], {
                  class: "inline-block text-cafe-dark w-4 h-4 mr-3",
                  name: "export_excel"
                }),
                _cache[11] || (_cache[11] = _createElementVNode("label", { class: "text-gray-secondary cursor-pointer" }, "Download Template", -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("p", _hoisted_27, _toDisplayString($setup.errAgreement), 1),
        _createElementVNode("div", {
          onClick: _cache[2] || (_cache[2] = (e) => $setup.openModal(e, $setup.titleVerify.TermsConditions)),
          class: "flex justify-start items-center hover:cursor-pointer"
        }, [
          _createVNode(_component_el_checkbox, {
            label: "By checking the box, I agree to Terms & Conditions",
            modelValue: $setup.isTermsConditions,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.isTermsConditions) = $event)),
            size: "large"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", {
          onClick: _cache[4] || (_cache[4] = (e) => $setup.openModal(e, $setup.titleVerify.PrivacyPolicies)),
          class: "flex justify-start items-center hover:cursor-pointer"
        }, [
          _createVNode(_component_el_checkbox, {
            label: "By checking the box, I agree to Privacy Policies",
            modelValue: $setup.isPrivacyPolicies,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.isPrivacyPolicies) = $event)),
            size: "large"
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", {
          onClick: _cache[6] || (_cache[6] = (e) => $setup.openModal(e, $setup.titleVerify.KYCAML)),
          class: "flex justify-start items-center hover:cursor-pointer"
        }, [
          _createVNode(_component_el_checkbox, {
            modelValue: $setup.isKYCAML,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.isKYCAML) = $event)),
            label: "By checking the box, I agree to KYC & AML",
            size: "large"
          }, null, 8, ["modelValue"])
        ]),
        _cache[15] || (_cache[15] = _createElementVNode("hr", { class: "border-2 mt-14" }, null, -1)),
        _cache[16] || (_cache[16] = _createElementVNode("div", { class: "flex justify-end items-center 2xl:space-x-5 space-x-3 2xl:py-5 py-3" }, [
          _createElementVNode("div", { class: "flex" }, [
            _createElementVNode("button", { class: "btn btn--black-primary" }, "Cancel"),
            _createElementVNode("button", {
              class: "btn btn--green-primary ml-5",
              type: "submit"
            }, " Save ")
          ])
        ], -1))
      ], 2)
    ]),
    _: 1
  }, 8, ["validation-schema"]))
}