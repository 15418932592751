import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";
import { titleVerify } from "@/constants/";
import { privacyPolicy, KycAml, TermsConditions } from "./contentVerify";
import { onMounted, ref, watch } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'KYC',
  setup(__props, { expose: __expose }) {
  __expose();

const content = ref("");
const contentChange = (key: string) => {
  switch (key) {
    case titleVerify.TermsConditions:
      content.value = TermsConditions;
      break;
    case titleVerify.KYCAML:
      content.value = KycAml;
      break;
    default:
      content.value = privacyPolicy;
      break;
  }
};
const handleClose = () => {
  DialogModule.hideModal();
};
const initialValues = DialogModule.getData as any;
const handleConfirm = () => {
  const { rest } = DialogModule.getRest as any;
  rest(initialValues.key);
  DialogModule.hideModal();
};
onMounted(() => {
  contentChange(initialValues.key);
});
watch(initialValues.key, () => {
  contentChange(initialValues.key);
});

const __returned__ = { content, contentChange, handleClose, initialValues, handleConfirm, get DialogModule() { return DialogModule }, get titleVerify() { return titleVerify }, get privacyPolicy() { return privacyPolicy }, get KycAml() { return KycAml }, get TermsConditions() { return TermsConditions }, onMounted, ref, watch }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})