import { defineComponent as _defineComponent } from 'vue'
import AccountModule from "@/store/modules/Account";
import { Field, Form } from "vee-validate";
import { IRegisterDTO } from "@/models/User";
import { useRoute, useRouter } from "vue-router";
import { object, ref as yupRef, string, mixed } from "yup";
import { computed, onMounted, ref, watch } from "vue";
import { ElMessage } from "element-plus/lib/components/message";
import { ICountries } from "@/models/Metadata";
import MetadataModule from "@/store/modules/Metadata";
import CompanyModule from "@ems/containers/CompanyAccountManagement/CompanyAccountManagement.module";
import isEmpty from "lodash/isEmpty";
import { PREFIX_PHONE_NUMBER } from "@ems/constants/phone";
import size from "lodash/size";
import { ICompanyDTO } from "@/models/Company";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import DialogKYC from "@ems/containers/Auth/TheDialog/KYC.vue";
import DialogModule from "@/store/UI/Dialog";
import { titleVerify } from "@/constants/";

export default /*@__PURE__*/_defineComponent({
  __name: 'RedeemRegistration.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const router = useRouter();
const route = useRoute();

const schema = object({
  Email: string().required().email().label("Email"),
  Username: string().required().label("User Name"),
  Password: string()
    .required()
    .min(8)
    .max(64)
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/,
      "Your password must include a combination of numbers, upper, lower, case letters and symbols"
    )
    .label("Password"),
  ConfirmPassword: string()
    .required("Confirm Password is required")
    .oneOf([yupRef("Password"), null], "Your passwords are not the same"),
  CompanyName: string().required().label("Company Name"),
  PhoneNumber: string().required().label("Phone Number"),
  Address: string().required().label("Address"),
  CountryId: string().required().label("Country Of Registration"),
  City: string().required().label("City"),
  Province: string().required().label("Province"),
  File: mixed().required("Company Registration Documents is required"),
  FileLetter: mixed().required("Letter of Authorization is required"),
});
const phoneCode = ref(["+84"] as any);
const phoneCodeUser = ref(["+84"] as any);
const invitationCode: any = computed(() => route.query["redeem-invitation"]);
const isTermsConditions = ref(false);
const isPrivacyPolicies = ref(false);
const isKYCAML = ref(false);
const errAgreement = ref("");
const onSubmit = async (values: any) => {
  if (!isKYCAML.value || !isTermsConditions.value || !isPrivacyPolicies.value) {
    errAgreement.value = "Please check the agreement to proceed";
    return;
  }
  const data = {
    Code: invitationCode.value,
    Email: values.Email,
    Username: values.Username,
    Password: values.Password,
    PhoneNumber: `${phoneCodeUser.value}-${values.PhoneNumberUser}`,
    FirstName: values.FirstName,
    LastName: values.LastName,
  };

  const dataAccount = await AccountModule.registration(data as IRegisterDTO);

  if (dataAccount) {
    const dataCompany = {
      TenantId: dataAccount.Id,
      CompanyName: values.CompanyName,
      CountryId: values.CountryId,
      PhoneNumber: `${phoneCode.value}-${values.PhoneNumber}`,
      Address: values.Address,
      City: values.City,
      Province: values.Province,
      CompanyRegistrationNo: values.CompanyRegistrationNo,
      NumberOfWorkers: values.NumberOfWorkers,
      FileUpload: values.File,
      FileLetter: values.FileLetter,
    } as ICompanyDTO;
    CompanyModule.createDataRegisterCompany(dataCompany);
  }
  if (!AccountModule.isError) {
    router.push({
      path: "/login",
      query: { "redeem-invitation": `${invitationCode.value}` },
    });
  }
};

const NUMBER_FORM = {
  MAX_FORM: 5,
  MIN_FORM: 0,
  MAX_FILES: 3,
  VN_CODE: "+84",
  PLUS: "+",
};

const options = ref<ICountries[]>([]);

const allCountries = computed(() => MetadataModule.allCountriesGetter);

const errorMessageRef = ref("");
const remoteMethod = (query: string) => {
  if (query) {
    setTimeout(() => {
      options.value = allCountries.value.filter((item) => {
        return item.Name.toLowerCase().includes(query.toLowerCase());
      });
    }, 200);
  } else if (query === "") {
    options.value = allCountries.value;
  } else {
    options.value = [];
  }
};
onMounted(async () => {
  filterOptionCountry();
  MetadataModule.getAllCountryAction();
});

watch(allCountries, () => {
  filterOptionCountry();
});

const filterOptionCountry = () => {
  options.value = allCountries.value.map((item) => {
    return { Value: `${item.Value}`, Name: `${item.Name}` };
  });
};

const handlePhoneCode = (query: string) => {
  phoneCode.value = query;
};
const handlePhoneCodeUser = (query: string) => {
  phoneCodeUser.value = query;
};
const beforeUploadFile = (file: any) => {
  const isLt20M = file.size / 1024 / 1024 < 20;

  if (!isLt20M) {
    ElMessage.error("File size can not exceed 20MB!");
  }
  return isLt20M;
};
const onChangeFile = (
  file: any,
  showFileList: any,
  value: any,
  setFieldValue: any,
  field: string
) => {
  if (size(showFileList) + size(value) > NUMBER_FORM.MAX_FILES) {
    ElMessage.error("Do not upload more than 3 files!");
  } else {
    setFieldValue(field, showFileList);
  }
};
const handleRemoveUploadedFile = (
  value: any,
  file: any,
  showFileList: any,
  setFieldValue: any,
  field: string
) => {
  if (showFileList.length + value?.length <= NUMBER_FORM.MAX_FILES) {
    ElMessage.success("You can update uploaded files!");
  }
  setFieldValue(field, showFileList);
};
const handlePreview = (uploadFile: any) => {
  window.open(URL.createObjectURL(uploadFile.raw));
};
const setConfirmVerify = (key: string) => {
  switch (key) {
    case titleVerify.TermsConditions:
      isTermsConditions.value = true;
      break;
    case titleVerify.PrivacyPolicies:
      isPrivacyPolicies.value = true;
      break;
    default:
      isKYCAML.value = true;
      break;
  }
  if (isKYCAML.value && isTermsConditions.value && isPrivacyPolicies.value) {
    errAgreement.value = "";
  }
};
const openModal = (e: Event, key: string) => {
  e.preventDefault(); // Now link won't go anywhere
  e.stopImmediatePropagation();

  DialogModule.showModal({
    component: DialogKYC,
    data: {
      key,
      titleVerify,
    },
    rest: (key: string) => setConfirmVerify(key),
  });
};

const __returned__ = { router, route, schema, phoneCode, phoneCodeUser, invitationCode, isTermsConditions, isPrivacyPolicies, isKYCAML, errAgreement, onSubmit, NUMBER_FORM, options, allCountries, errorMessageRef, remoteMethod, filterOptionCountry, handlePhoneCode, handlePhoneCodeUser, beforeUploadFile, onChangeFile, handleRemoveUploadedFile, handlePreview, setConfirmVerify, openModal, get AccountModule() { return AccountModule }, get Field() { return Field }, get Form() { return Form }, get IRegisterDTO() { return IRegisterDTO }, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get object() { return object }, get yupRef() { return yupRef }, get string() { return string }, get mixed() { return mixed }, computed, onMounted, ref, watch, get ElMessage() { return ElMessage }, get ICountries() { return ICountries }, get MetadataModule() { return MetadataModule }, get CompanyModule() { return CompanyModule }, get isEmpty() { return isEmpty }, get PREFIX_PHONE_NUMBER() { return PREFIX_PHONE_NUMBER }, get size() { return size }, get ICompanyDTO() { return ICompanyDTO }, BaseSvgIcon, DialogKYC, get DialogModule() { return DialogModule }, get titleVerify() { return titleVerify } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})