import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "rounded-lg",
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    width: "40%",
    center: "",
    "before-close": $setup.handleClose
  }, {
    title: _withCtx(() => _cache[2] || (_cache[2] = [
      _createElementVNode("h1", { class: "text-center text-2xl font-bold" }, " Please read through the documents to agree. ", -1)
    ])),
    footer: _withCtx(() => [
      _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "text-left text-lg 2xl:text-xl font-bold mb-2" }, " By Clicking the button below, I understand and agree to the above. ", -1)),
      _createElementVNode("div", { class: "flex justify-start items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary",
          onClick: $setup.handleConfirm
        }, " Agree ")
      ])
    ]),
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("textarea", {
        rows: "20",
        disabled: "",
        class: "h-1/2",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.content) = $event))
      }, null, 512), [
        [_vModelText, $setup.content]
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}